@import "../../styles/_vars";
@import '../../styles/_media';
@import "../../styles/_mixins";

.download-card {
    box-shadow: $shadow;
    width: 325px;
    height: 360px;
    border-radius: 16px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &:first-child {
        margin-right: 32px;
        margin-bottom: 32px;
    }
    img, button {
        width: 100%;
    }    
}

@media(min-width: 1700px) {
    .b-modal .download-card {
        margin: 0;
        &:first-child {
            margin-right: 32px;
            margin-left: 16px;
        }
        &:last-child {
            margin-right: 16px;
        }
    }
}
@media(max-width: 1700px) {
    .download-cards-container {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}
@media(max-width: 928px) {
    .b-modal .download-card {
        margin: 0;
        margin-bottom: 32px;   
    }
    .download-cards-container > .b-row{
        flex-direction: column;
    }
}