.b-balance__modal__controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .b-button {
        width: 217px;
    }
}
.b-balance__warning {
    display: flex;
    align-items: center;
    img {
        margin-right: 1rem;
    }
}
