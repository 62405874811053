@import '../../styles/_vars';
@import '../../styles/_media';

.b-profile-form {
  width    : 100%;
  max-width: 450px;

  .b-textfield__postfix {
    width: unset;
  }

  @include media('<tablet') {
    max-width: 100%;
  }
}

.b-avatar {
  width           : 200px;
  height          : 200px;
  position        : relative;
  border-radius   : 50px;
  background-color: $grey;

  &__img {
    width        : inherit;
    height       : inherit;
    object-fit   : contain;
    border-radius: inherit;
  }

  &__placeholder {
    width  : 120px;
    height : 120px;
    padding: 40px;
  }

  &__edit {
    right   : -8px;
    width   : 50px;
    height  : 50px;
    bottom  : -8px;
    padding : 0;
    position: absolute;
  }

  @include media('<tablet') {
    width        : 100px;
    height       : 100px;
    border-radius: 25px;

    &__placeholder {
      width  : 60px;
      height : 60px;
      padding: 20px;
    }

    &__edit {
      width : 35px;
      height: 35px;

      img {
        width : 20px;
        height: 20px;
      }
    }
  }
}

.b-plan-card {
  display         : flex;
  padding         : 40px;
  max-width       : 750px;
  box-shadow      : 0px 4px 16px rgba(54, 160, 242, 0.4);
  line-height     : 1.3;
  border-radius   : 20px;
  background-color: white;

  &__sm {
    display: none;
  }

  &__col {
    flex          : 1;
    display       : flex;
    flex-direction: column;

    .b-button {
      margin-top: auto;
    }

    &:first-child {
      margin-right: 60px;
    }
  }

  @include media('<desktop') {
    position      : relative;
    flex-direction: column;
    padding-bottom: 130px;

    &__sm {
      display: flex;
    }

    &__lg {
      display: none;
    }

    &__col {
      .b-button {
        width   : calc(100% - 80px);
        bottom  : 40px;
        position: absolute;
      }

      &:first-child {
        margin-right : 20px;
        margin-bottom: 24px;
      }
    }
  }

  @include media('<tablet') {
    padding       : 24px;
    max-width     : calc(100vw - 32px - 48px);
    padding-bottom: 100px;

    &__col {
      .b-button {
        width : calc(100% - 48px);
        bottom: 24px;
      }
    }
  }
}

.b-download-card {
  border         : 1px solid #E3E3E3;
  display        : flex;
  padding        : 17px 27px;
  min-height     : 120px;
  transition     : 0.4s;
  box-sizing     : border-box;
  align-items    : center;
  margin-right   : 16px;
  margin-bottom  : 16px;
  border-radius  : 10px;
  justify-content: center;

  img {
    max-width: 307px;
  }

  &:hover {
    box-shadow: 0px 4px 16px rgba(54, 160, 242, 0.4);
  }

  @include media('<tablet') {
    padding     : 16px 24px;
    margin-right: 0;

    img {
      width: calc(100vw - 32px - 48px);
    }
  }
}

.b-platform-card {
  margin       : 0 40px;
  padding      : 40px 45px 35px 45px;
  background   : white;
  box-shadow   : 0px 4px 36px rgba(54, 160, 242, 0.4);
  white-space  : nowrap;
  border-radius: 20px;

  display        : flex;
  flex-direction : column;
  justify-content: space-between;

  img {
    margin   : 32px 0;
    max-width: 307px;
  }

  @include media('<desktop') {
    width  : calc(50% - 110px);
    margin : 0 20px;
    padding: 35px;

    img {
      width     : 100%;
      height    : auto;
      object-fit: contain;
    }
  }

  @include media('<tablet') {
    width        : calc(100vw - 32px - 32px);
    height       : 276px;
    margin       : 0;
    padding      : 16px 24px;
    box-sizing   : border-box;
    margin-right : 0;
    margin-bottom: 32px;

    &:last-child {
      margin: 0;
    }

    img {
      width : calc(100vw - 32px - 32px - 48px);
      height: auto;
      margin: 16px 0;
    }
  }
}

.b-balance {
  border: 1px solid #F1F2F4;
  padding: 32px 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 24px;
  &__card {
    gap: 8px;
    width: 182px;
    min-height: 136px;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    &:not(:last-of-type) {
      border-right: 1px solid #F1F2F4;
    }
    .b-button {
      margin-top: 24px;
    }
    &__controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .b-button {
        width: 83px;
      }
    }
    &__wline {
      width: 888px;
      padding-left: 16px;
      padding-right: 16px;
      .b-button {
        margin-top: 24px;
        width: 182px;
      }
    }
  }
}
@media(max-width: 700px) {
  .b-balance {
    padding: 16px;
  
    flex-direction: column;
    &__card {
      padding-top: 16px;
      padding-bottom: 16px;
      justify-content: center;
      &:not(:last-of-type) {
        border-right: 0;
        border-bottom: 1px solid #F1F2F4;
      }
    }
  }

}

.downloads-modal .b-modal__content{
  padding: 0 !important;
  padding-bottom: 40px !important;
  .downloads-moda-head {
    position: sticky;
    top: 0;
    background: white;
    padding: 40px;
    border-top-left-radius: 30px;
    z-index: 3;
  }
}
