@import "../../styles/_vars";
@import "../../styles/_media";
@import "../../styles/_mixins";

.b-error {
  &__label {
    color     : $red-dark;
    margin-top: 16px;
    @include font(14px);
  }

  @include media('<tablet') {
    &__label {
      margin-top: 8px;
      @include font(12px);
    }
  }
}