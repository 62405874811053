@import '../../styles/_vars';
@import '../../styles/_media';
@import '../../styles/_mixins';

.b-checkbox {
  display: flex;

  &__input {
    opacity : 0;
    z-index : -1;
    position: absolute;

    &+label {
      display    : inline-flex;
      user-select: none;
    }

    &+label::before {
      width              : 14px;
      height             : 14px;
      border             : 2px solid $border;
      content            : '';
      display            : inline-block;
      flex-grow          : 0;
      margin-top         : 2px;
      transition         : 0.4s;
      flex-shrink        : 0;
      margin-right       : 20px;
      border-radius      : 4px;
      background-size    : 12px 12px;
      background-color   : white;
      background-repeat  : no-repeat;
      background-position: center center;
    }

    &[type='radio']+label::before {
      border-radius: 50%;
    }

    &:focus+label::before,
    &:focus:not(:checked)+label::before,
    &:not(:disabled):not(:checked)+label:hover::before {
      border-color: $blue-light;
    }

    &:not(:disabled):active+label::before {
      background-color: $blue-light;
    }

    &:checked+label::before {
      border-color    : $blue;
      background-color: $blue;
      background-image: url('../../assets/svg/checkmark.svg');
    }

    &:disabled+label {
      color: $black-light;
    }

    &:checked:disabled+label::before {
      border-color    : $blue-light;
      background-color: $blue-light;
    }

    &:not(:checked):disabled+label::before {
      border-color    : $grey;
      background-color: $grey;
    }
  }

  &__label {
    color: $black;
    @include font(18px);
  }

  @include media('<tablet') {
    &__input {
      &+label::before {
        margin-right: 16px;
      }
    }

    &__label {
      @include font(16px);
    }
  }
}