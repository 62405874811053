@import "../../styles/_vars";
@import '../../styles/_media';
@import "../../styles/_mixins";

.b-select {
  position: relative;

  .b-textfield__input {
    font-weight: 500;
  }

  &--open {
    .b-select__arrow img {
      transform: none;
    }
  }

  &__arrow {
    width          : 10px;
    height         : 24px;
    display        : flex;
    align-items    : center;
    justify-content: center;

    img {
      transform : scaleY(-1);
      transition: transform 0.2s;
    }
  }

  &__option {
    height       : 28px;
    cursor       : pointer;
    padding      : 8px 16px;
    transition   : 0.4s;
    line-height  : 26px;
    margin-bottom: 8px;
    border-radius: 16px;

    &:last-child {
      margin: 0;
    }

    &:hover,
    &--selected {
      background-color: #F3FAFF;
    }

    &--selected {
      color      : $blue;
      font-weight: 500;
    }
  }

  &__toggle {

    &,
    .b-textfield__input {
      cursor: pointer;
    }
  }

  &__dropdown {
    width           : 100%;
    border          : 1px solid #E3E3E3;
    padding         : 8px;
    z-index         : 999;
    position        : absolute;
    box-sizing      : border-box;
    margin-top      : 8px;
    border-radius   : 16px;
    background-color: white;
  }

  @include media('<tablet') {
    &__arrow {
      width      : 10px;
      height     : 20px;
      margin-left: 10px;

      img {
        width : 12px;
        height: 7px;
      }
    }

    &__option {
      height     : 20px;
      padding    : 6px 12px;
      line-height: 20px;
    }
  }
}