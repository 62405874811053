@import '../../styles/_vars';
@import '../../styles/_media';
@import '../../styles/_mixins';

.b-tabs {
  display: flex;

  &__tab {
    color        : $grey-text;
    cursor       : pointer;
    padding      : 8px 0 8px 20px;
    position     : relative;
    white-space  : nowrap;
    line-height  : 22px;
    margin-bottom: 16px;
    @include font(18px, 500);

    &--selected {
      color      : $black;
      font-weight: 600;

      &:before {
        top             : 0;
        left            : 0;
        width           : 4px;
        bottom          : 0;
        content         : '';
        display         : block;
        position        : absolute;
        border-radius   : 20px;
        background-color: $blue;
      }
    }

    &:last-child {
      margin: 0;
    }
  }

  &__list {
    margin         : 0;
    padding        : 0;
    margin-right   : 120px;
    list-style-type: none;
  }

  &__content {
    flex: 1;
  }

  @include media('<desktop') {
    &__list {
      margin-right: 100px;
    }
  }

  @include media('<tablet') {
    display: block;

    &__tab {
      padding      : 8px 0 16px 0;
      line-height  : 18px;
      user-select  : none;
      margin-right : 16px;
      margin-bottom: 0;
      @include font(16px, 500);

      &--selected {
        &:before {
          top   : 38px;
          width : 100%;
          height: 4px;
          bottom: 0;
        }
      }
    }

    &__list {
      width         : 100%;
      display       : flex;
      overflow      : scroll;
      max-width     : 100%;
      white-space   : nowrap;
      margin-right  : 0;
      padding-bottom: 16px;
      @include scrollbar(transparent, true);
    }

    &__list-wrapper {
      height       : 42px;
      overflow     : hidden;
      margin-bottom: 24px;
    }
  }
}