@import "../../styles/_vars";
@import '../../styles/_media';
@import "../../styles/_mixins";

.b-modal {
  top             : 0;
  left            : 0;
  width           : 100%;
  height          : 100%;
  opacity         : 0;
  z-index         : 10;
  display         : flex;
  position        : fixed;
  transition      : opacity 0.5s ease;
  align-items     : center;
  flex-direction  : column;
  pointer-events  : none;
  justify-content : center;
  background-color: rgba(0, 0, 0, 0.5);

  &--open {
    opacity       : 1;
    pointer-events: auto;
  }

  &--scrollable {
    .b-modal__content {
      overflow-y   : auto;
      overflow-x   : hidden;
      box-sizing   : border-box;
      max-height   : calc(100vh - 50px);
      margin-right : 20px;
      padding-right: 20px;
      padding-left: 40px;
    }
  }

  &__content {
    padding: 40px 40px 40px 40px;
  }

  &__container {
    position        : relative;
    max-height      : calc(100vh - 50px);
    border-radius   : 20px;
    background-color: white;
  }

  &__close {
    top             : -17px;
    right           : -17px;
    width           : 35px;
    height          : 35px;
    cursor          : pointer;
    padding         : 0;
    display         : flex;
    position        : absolute;
    transition      : 0.4s;
    align-items     : center;
    border-radius   : 50%;
    justify-content : center;
    background-color: $black;

    img {
      width : 25px;
      height: 25px;
    }
  }

  @include media('<desktop') {
    &--scrollable {
      .b-modal__content {
        max-height: calc(100vh - 170px);
      }
    }

    &__container {
      max-width : calc(100vw - 70px);
      max-height: calc(100vh - 170px);
    }
  }

  @include media('<tablet') {
    &--scrollable {
      .b-modal__content {
        max-height: calc(100vh - 130px);
      }
    }

    &__content {
      padding: 16px;
    }

    &__container {
      max-width : calc(100vw - 32px);
      max-height: calc(100vh - 130px);
    }

    &__close {
      top   : -10px;
      right : -10px;
      width : 35px;
      height: 35px;

      img {
        width : 25px;
        height: 25px;
      }
    }
  }
}