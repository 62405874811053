@import "../../styles/_vars";
@import '../../styles/_media';
@import "../../styles/_mixins";

.b-button {
  border         : 1px solid $blue;
  cursor         : pointer;
  display        : flex;
  position       : relative;
  transition     : 0.4s;
  box-sizing     : border-box;
  align-items    : center;
  border-radius  : 8px;
  text-decoration: none;
  justify-content: center;

  @include font(18px, 700);

  &__loader {
    margin-top : 4px;
    margin-left: 20px;
  }

  &__postfix {
    display    : flex;
    margin-left: 16px;
  }

  &:disabled {
    pointer-events: none;

    .b-button__postfix {
      opacity: 0.5;
    }
  }

  &--loading {
    pointer-events: none;
  }

  &--fullWidth {
    width: 100% !important;
  }

  // Color

  &--primary {
    color           : white;
    box-shadow      : $shadow;
    background-color: $blue;

    &:focus,
    &:hover,
    &:active {
      background-color: $blue-dark;
    }

    &:focus,
    &:hover {
      box-shadow: $shadow-active;
    }

    &:active {
      box-shadow: 0px 4px 25px $blue-dark;
    }

    &:disabled {
      box-shadow      : none;
      border-color    : $blue-light;
      background-color: $blue-light;
    }
  }

  &--outline {
    color           : $blue;
    background-color: transparent;

    &:focus,
    &:hover,
    &:active {
      color: $blue-dark;
    }

    &:focus,
    &:hover {
      box-shadow: $shadow-active;
    }

    &:active {
      box-shadow: 0px 4px 25px $blue-dark;
    }

    &:disabled {
      color : $blue-light;
      border: 2px solid $blue-light;
    }
  }

  &--shadow {
    color           : $black;
    border-color    : white;
    box-shadow      : $shadow;
    background-color: white;

    &:focus,
    &:hover {
      box-shadow: $shadow-active;
    }

    &:active {
      box-shadow: 0px 4px 25px $blue-dark;
    }

    &:disabled {
      color : $blue-light;
      border: 2px solid $blue-light;
    }
  }

  &--danger {
    color           : white;
    box-shadow      : $shadow-error;
    border-color    : $red;
    background-color: $red;

    &:focus,
    &:hover,
    &:active {
      background-color: $red-dark;
    }

    &:focus,
    &:hover {
      box-shadow: $shadow-error;
    }

    &:active {
      box-shadow: 0px 4px 25px $red;
    }

    &:disabled {
      box-shadow      : none;
      border-color    : transparent;
      background-color: #EB575773;
    }
  }

  // Size

  &--lg {
    height : 64px;
    padding: 0 28px;
  }

  &--md {
    height : 50px;
    padding: 0 24px;
  }

  @include media('<tablet') {
    @include font(16px, 700);

    &--md,
    &--lg {
      height : 50px;
      padding: 0 20px;
    }
  }
}