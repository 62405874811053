@import '../../styles/_vars';
@import '../../styles/_media';
@import '../../styles/_mixins';

$headerHeight: 110px;

.b-layout {
  height : 100vh;
  display: flex;

  &__content {
    height      : calc(100vh - 134px);
    padding     : 40px 20px 40px 40px;
    overflow-y  : auto;
    overflow-x  : hidden;
    box-sizing  : border-box;
    margin-right: 20px;

    @include scrollbar();
  }

  &__container {
    flex  : 1;
    height: inherit;
  }
}

.b-header {
  height         : $headerHeight;
  display        : flex;
  padding        : 0 60px;
  align-items    : center;
  border-bottom  : 1px solid rgba(50, 69, 82, 0.2);
  justify-content: space-between;

  h1 {
    margin: 0;
    @include font(24px, 600);
  }

  &__profile {
    display    : flex;
    align-items: center;
  }

  &__avatar {
    width           : 50px;
    height          : 50px;
    border          : 2px solid $blue;
    object-fit      : cover;
    margin-right    : 20px;
    border-radius   : 10px;
    background-color: #EEEFF4;

    &--placeholder {
      width  : 40px;
      height : 40px;
      padding: 5px;
    }
  }

  &__divider {
    width           : 1px;
    border          : none;
    height          : $headerHeight - 32px;
    margin          : 16px 24px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &__burger {
    display: none;

    cursor      : pointer;
    height      : 25px;
    padding     : 2px 0;
    margin-right: 16px;

    span {
      width        : 20px;
      height       : 2px;
      margin       : 4px 8px;
      display      : block;
      background   : $blue;
      transition   : 0.4s;
      border-radius: 4px;
    }

    &:hover span {
      background: $blue-dark;
    }
  }

  &__balance {
    font-size: 14px;
    text-align: end;
    margin-right: 24px;

    b {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 4px;
    }

    div {
      color: $grey-text;
    }
  }

  .b-tariffs {
    height          : max-content;
    max-width       : calc(100vw - 140px);
    background-color: white !important;

    h1 {
      @include font(24px, bold);
    }

    &__inner {
      padding       : 0;
      overflow      : auto;
      max-height    : calc(100vh - 50px - 60px - 40px - 40px);
      padding-bottom: 40px;
    }
  }
  &__weblink {
    margin-left: 24px; 
    a {
      text-decoration: none;
      color: white;
    }
  }
}

.b-side-menu {
  width           : 300px;
  height          : inherit;
  display         : flex;
  min-width       : 300px;
  flex-direction  : column;
  background-color: $blue-dark-bg;

  &__tariffs {
    display: none;
  }

  &__logo {
    height         : 110px;
    margin         : 0 40px;
    display        : flex;
    align-items    : center;
    border-bottom  : 1px solid #F2F2F4;
    justify-content: center;

    img {
      width : auto;
      height: 50px;
    }
  }

  &__list {
    padding: 45px 40px;
  }

  &__item {
    margin-bottom: 24px;

    &:last-child {
      margin: 0;
    }

    img {
      filter      : brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(106%);
      margin-right: 16px;
    }

    a,
    button {
      cursor         : pointer !important;
      color          : white;
      display        : flex;
      transition     : 0.2s;
      align-items    : center;
      text-decoration: none;
      @include font(18px, bold);

      &:hover {
        opacity: 0.7;
      }
    }

    &--active {

      a,
      button {
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &__footer {
    margin    : 0 40px;
    padding   : 26px 0;
    border-top: 1px solid #F2F2F4;
    margin-top: auto;
  }

  &__overlay {
    top             : 0;
    left            : 0;
    right           : 0;
    bottom          : 0;
    z-index         : 3;
    opacity         : 0;
    position        : fixed;
    visibility      : hidden;
    transition      : visibility 0s, opacity 0.4s;
    background-color: rgba(0, 0, 0, 0.5);

    &--visible {
      opacity   : 1;
      visibility: visible;
    }
  }
}
.b-signin {
  &__logo {
    height         : 110px;
    display        : flex;
    align-items    : center;
    justify-content: center;
    img {
      width : auto;
      height: 75px;
    }
  }
}

@include media('<desktop') {
  $headerHeight: 95px;

  .b-header {
    height : $headerHeight;
    padding: 0 52px 0 34px;

    &__burger {
      display: block;
    }
    &__weblink {
      display: none;
    }

    // &__divider,
    // &__subscription {
    //   display: none;
    // }

    .b-lang-select {
      margin-right: 24px;
    }

    #header-plans-modal {
      display: none;
    }
  }

  .b-side-menu {
    z-index   : 4;
    position  : fixed;
    transform : translateX(-300px);
    transition: 0.4s;

    &--open {
      transform: translateX(0);
    }

    &__tariffs {
      display: block;
    }

    &__logo {
      height: 95px;
    }
  }

  .b-layout {
    &__content {
      height: calc(100vh - 96px);
    }

    &__container {
      flex : unset;
      width: 100%;
    }
  }
}

@include media('>desktop') {
  .b-header .b-button {
    min-width: 150px;
  }
  // .b-modal-lg-wide {
  //   max-width: 90%;
  // }
}

@include media('<tablet') {
  $headerHeight: 80px;

  .b-header {
    height : $headerHeight;
    padding: 0 16px 0 10px;

    h1 {
      @include font(20px, 600);
    }

    &__avatar {
      width       : 35px;
      height      : 35px;
      margin-right: 0;

      &--placeholder {
        width : 20px;
        height: 20px;
      }
    }

    &__profile-info {
      display: none;
    }

    &__balance {
      font-size: 12px;
  
      b {
        font-size: 20px;
      }
    }
  }

  .b-side-menu {
    height: 100%;

    &__logo {
      height         : 80px;
      margin         : 0 16px;
      display        : flex;
      align-items    : center;
      border-bottom  : 1px solid #F2F2F4;
      justify-content: center;

      img {
        height: 40px;
        margin: 8px 0;
      }
    }

    &__list {
      padding: 24px 16px;
    }

    &__item {
      margin-bottom: 16px;

      a,
      button {
        @include font(16px, bold);

        &>img {
          width : 20px;
          height: 20px
        }
      }
    }

    &__footer {
      margin    : 0 16px;
      padding   : 20px 0;
      margin-top: auto;
    }
  }

  .b-layout {
    height: auto;

    &__content {
      height      : unset;
      padding     : 16px;
      margin-right: 0;
    }

    .b-tariffs {
      margin: 0 -16px;

      &__inner {
        padding: 0 16px;
      }
    }
  }
}

@include media('<desktop') {
  .b-layout {
    .b-tariffs--blue {
      background-color: transparent;
    }
  }

  @include media('>=tablet', '<desktop') {
    .b-layout {
      .b-tariffs__inner {
        padding: 0;
      }
    }
  }
}