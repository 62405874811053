.b-loading {
  position: relative;

  &__indicator {
    top      : 50%;
    left     : 50%;
    z-index  : 1;
    position : absolute;
    transform: translate(-50%, -50%);
  }

  &__container {
    filter     : blur(2px);
    margin     : -16px;
    padding    : 16px;
    overflow   : hidden;
    user-select: none;
  }
}