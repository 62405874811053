@import "../../styles/_vars";
@import '../../styles/_media';
@import "../../styles/_mixins";

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.b-textfield {
  border       : 2px solid white;
  display      : flex;
  position     : relative;
  transition   : 0.2s;
  background   : white;
  box-shadow   : $shadow;
  box-sizing   : border-box;
  align-items  : center;
  border-radius: 16px;

  textarea {
    resize     : none;
    white-space: pre;
  }

  &--md {
    height : 50px;
    padding: 0 24px;
  }

  &--lg {
    height : 64px;
    padding: 0 24px;
  }

  &--focused {
    border-color: $blue;
  }

  &--error {
    box-shadow  : 0px 4px 16px rgba(235, 87, 87, 0.4);
    border-color: $red;
  }

  &--disabled {
    box-shadow      : none;
    border-color    : #F8F8F8;
    background-color: #F8F8F8;
  }

  &__input {
    color     : $black;
    width     : 100%;
    height    : 100%;
    border    : none;
    padding   : 0;
    outline   : none !important;
    background: transparent;
    @include font(18px);

    @include placeholder {
      color: #8B949B;
    }
  }

  &__label {
    color        : $black;
    display      : block;
    margin-bottom: 8px;
    @include font(18px, 600);
  }

  &__prefix,
  &__postfix {
    width : 24px;
    height: 24px;
  }

  &__prefix {
    margin-right: 24px;
  }

  &__postfix {
    margin-left: 24px;
  }

  @include media('<tablet') {

    &--md,
    &--lg {
      height : 50px;
      padding: 0 20px;
    }

    &__input {
      @include font(16px);
    }

    &__label {
      @include font(16px, 600);
    }

    &__prefix,
    &__postfix {

      &,
      img {

        width : 20px;
        height: 20px;
      }
    }

    &__prefix {
      margin-right: 20px;
    }

    &__postfix {
      margin-left: 20px;
    }

  }
}