@import '../../styles/_vars';
@import '../../styles/_media';
@import '../../styles/_mixins';

.b-toast {
  .Toastify__toast {
    color: $black;
    @include font(18px, 500);
  }

  @include media('<tablet') {
    .Toastify__toast {
      @include font(16px, 500);
    }
  }
}