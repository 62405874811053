$blue         : #36A0F2;
$blue-dark    : #0D87E6;
$blue-light   : #9bd0f9;
$blue-dark-bg : #002A47;

$grey     : #EEEFF4;
$grey-text:#84898C;

$border: #D9DBE9;

$black      : #151C21;
$black-light: rgba(21, 28, 33, 0.6);

$placeholder: #8B949B;

$red     : #EB5757;
$red-dark: #FF132F;

$green: #1BCF67;

$shadow       : 0px 4px 16px rgba(54, 160, 242, 0.4);
$shadow-error : 0px 4px 16px rgba(235, 87, 87, 0.4);
$shadow-active: 0px 4px 16px $blue-dark;

$spacer: 8px;