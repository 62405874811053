@import '../../styles/_vars';
@import '../../styles/_media';
@import '../../styles/_mixins';

.b-text {
  margin: 0;

  // Size

  &--xs {

    &,
    a,
    button {

      @include font(14px)
    }
  }

  &--sm {

    &,
    a,
    button {

      @include font(16px)
    }
  }

  &--md {

    &,
    a,
    button {

      @include font(18px)
    }
  }

  &--lg {

    &,
    a,
    button {

      @include font(24px)
    }
  }

  // Weight

  &--normal {
    font-weight: 400;
  }

  &--medium {
    font-weight: 500;
  }

  &--semi {
    font-weight: 600;
  }

  &--bold {
    font-weight: 700;
  }

  // Position

  &--inline {

    &,
    div {
      display: inline-block;
    }
  }

  &--centered {
    text-align: center;
  }

  // Color

  &--grey {
    color: $black-light;
  }

  &--black {
    color: $black;
  }

  &--light {
    color: white
  }

  &--error {
    color: $red;
  }

  &--primary {
    color: $blue;
  }

  &--secondary {
    color: $blue-light;
  }

  @include media('<tablet') {
    &--xs {

      &,
      a,
      button {

        @include font(12px)
      }
    }

    &--sm {

      &,
      a,
      button {

        @include font(14px)
      }
    }

    &--md {

      &,
      a,
      button {

        @include font(16px)
      }
    }

    &--lg {

      &,
      a,
      button {

        @include font(22px)
      }
    }
  }
}