@import './_vars';
@import './_media';
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;500;600;700&display=swap');

html {
  background-color: white;
  font-size: 16px;
}

body {
  margin          : 0;
  background-color: white;
}

html,
input,
button {
  font-family: 'Exo 2', sans-serif;
}

button {
  border          : none;
  padding         : 0;
  outline         : none !important;
  background-color: transparent;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.b-link {
  color          : $blue;
  cursor         : pointer;
  transition     : 0.4s;
  font-weight    : 400;
  text-decoration: none !important;

  &:hover {
    color: $blue-dark;
  }

  &--disabled {
    color         : $grey-text;
    pointer-events: none;
  }
}

.b-row {
  display  : flex;
  flex-wrap: wrap;

  &__no-wrap {
    flex-wrap: nowrap;
  }

  &__negative {
    margin: -12px -16px;
  }

  &__justify-end {
    justify-content: flex-end;
  }

  &__justify-center {
    justify-content: center;
  }

  &__justify-between {
    justify-content: space-between;
  }

  &__align-end {
    align-items: flex-end;
  }
  &__align-start {
    align-items: flex-start;
  }

  &__align-center {
    align-items: center;
  }
}
@include media('<tablet') {
  .b-row-mobile-centered {
    align-items: center;
    justify-content: center;
  }
}

.b-spacer {
  &__1 {
    height: $spacer;
  }

  &__2 {
    height: $spacer * 2;
  }

  &__3 {
    height: $spacer * 3;
  }

  &__4 {
    height: $spacer * 4;
  }

  &__5 {
    height: $spacer * 5;
  }

  &__6 {
    height: $spacer * 5;
  }
}

.b-general-loading {
  top             : 0;
  left            : 0;
  right           : 0;
  bottom          : 0;
  z-index         : 10;
  display         : flex;
  position        : absolute;
  transition      : 0.4s;
  align-items     : center;
  justify-content : center;
  background-color: #F3FAFF;

  &--hidden {
    opacity            : 0;
    visibility         : hidden;
    transition-duration: 0.4s, 0.4s, 0;
    transition-property: opacity, top, visibility;
    transition-delay   : 0.4s, 0.4s, 0;
  }
}

.b-payment {
  width        : 800px;
  border       : 1px solid #E3E3E3;
  height       : calc(100vh - 50px - 120px - 32px);
  padding      : 16px;
  border-radius: 20px;
}

.b-confirm {
  .b-modal__content {
    width  : 350px;
    padding: 40px;
  }

  &__controls {
    margin         : 32px -16px 0 -16px;
    display        : flex;
    justify-content: space-between;

    .b-button {
      width : 50%;
      margin: 0 16px;
    }
  }
}

.b-success {
  width         : 500px;
  display       : flex;
  align-items   : center;
  flex-direction: column;

  img {
    width        : 120px;
    height       : 120px;
    margin-bottom: 24px;
  }
}

.b-cropper {
  width : 900px;
  height: 450px;
  margin: 10px;
}

.b-payment-modal {
  width: 570px;
  
  &__footer {
    gap: 24px;
    display: flex;
    
    .b-button {
      width: 50%;
    }
  }
}

@include media('<tablet') {
  .b-cropper {
    width : calc(100vw - 32px - 20px - 32px);
    height: calc(100vh - 30px - 20px - 32px - 24px - 36px - 80px);
  }

  .b-payment {
    padding: 0;
    width  : calc(100vw - 32px - 32px);
    height : calc(100vh - 30px - 20px - 32px - 24px - 36px - 80px);
  }

  .b-confirm {
    .b-modal__content {
      width  : calc(100vw - 64px);
      padding: 16px;
    }

    &__controls {
      margin         : 24px 0 0 0;
      display        : flex;
      justify-content: space-between;

      .b-button {
        width : 50%;
        margin: 0 8px;
      }
    }
  }

  .b-success {
    width         : calc(100vw - 64px);
    display       : flex;
    align-items   : center;
    flex-direction: column;

    img {
      width        : calc(100vw / 3);
      height       : auto;
      margin-bottom: 24px;
    }
  }
}