@import '../../styles/_vars';
@import '../../styles/_media';
@import '../../styles/_mixins';

.headin-no-outh-layout {
    padding-left: 50px;
}
.no-auth-content {
    padding-left: 80px;
    padding-right: 60px;
}
.b-header-noauth_link {
    color: black;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    padding: 10px 20px;
}
.no-auth-logo {
    border-bottom: 0px;
}
@include media('<desktop') {
    .no-auth-logo, .b-header-noauth_link {
        display: none;
    }
    .text-sm-center {
        text-align: center;
    }
}