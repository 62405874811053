.b-help {
    position: relative;
    cursor: pointer;
    margin-right: 32px;
    margin-bottom: 16px;
    &__preview {
        width: 370px;
        height: 248px;
        img {
            width: 100%;
            border-radius: 16px;
        }
    }   
    &__type {
        position: absolute;
        bottom: 50px;
        right: 12px;
    }
    &__title {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 600;
    }
}
