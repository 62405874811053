@import '../../styles/_vars';
@import '../../styles/_media';
@import '../../styles/_mixins';

.b-auth-layout {
  height : 100vh;
  display: flex;

  &__bg,
  &__form {
    flex   : 1;
    display: flex;
  }

  &__bg {
    background     : #F3FAFF;
    align-items    : center;
    justify-content: center;
  }

  &__bg-image {
    width              : 100%;
    height             : 100%;
    object-fit         : cover;
    background-image   : url('../../assets/svg/figures.svg');
    background-repeat  : no-repeat;
    background-position: center;
  }

  &__form {
    overflow-y: auto;
    background: white;

    @include scrollbar();

    form {
      width  : 460px;
      margin : auto;
      padding: 20px;
    }

    &:before {
      display: none;

      top          : -5%;
      right        : -25vw;
      width        : 150vw;
      height       : 55%;
      content      : '';
      position     : absolute;
      background   : linear-gradient(118.01deg, #1FA2FF 15.95%, #0092FA 46.92%, #007DD6 70.57%);
      border-radius: 30%;
    }
  }

  &__locale {
    top     : 20px;
    left    : 20px;
    z-index : 1;
    position: absolute;
  }

  @include media('<tablet') {
    &__bg {
      display: none;
    }

    &__form {
      overflow: hidden;
      position: relative;

      form {
        top          : 20%;
        left         : 16px;
        right        : 16px;
        width        : calc(100vw - 32px);
        padding      : 50px 24px;
        overflow     : auto;
        position     : absolute;
        background   : white;
        box-sizing   : border-box;
        box-shadow   : 0px 100px 80px rgba(0, 0, 0, 0.04);
        max-height   : calc(100vh - 20% - 16px);
        border-radius: 20px;

        @include scrollbar(transparent, true);
      }

      &:before {
        display: block;
      }
    }

    &__locale {
      top : 16px;
      left: 16px;
    }
  }

  @include media('>=tablet', '<desktop', 'portrait') {
    &__bg {
      display: none;
    }

    &__form {
      overflow: hidden;
      position: relative;

      form {
        top          : 20%;
        left         : 24px;
        right        : 24px;
        width        : calc(100vw - 48px);
        padding      : 50px 24px;
        overflow     : auto;
        position     : absolute;
        background   : white;
        box-sizing   : border-box;
        box-shadow   : 0px 100px 80px rgba(0, 0, 0, 0.04);
        max-height   : calc(100vh - 20% - 24px);
        border-radius: 20px;

        @include scrollbar(transparent, true);
      }

      &:before {
        display: block;
      }
    }

    &__locale {
      top : 24px;
      left: 24px;
    }
  }
}