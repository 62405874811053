@import '../../styles/_vars';
@import '../../styles/_media';
@import '../../styles/_mixins';

.b-documents {
  margin-top: -40px;

  &__select {
    min-width: 285px;

    img {
      margin-right: 16px;

      &:last-child {
        margin: 0;
      }
    }

    .b-textfield {
      &__postfix {
        margin-left: auto;
      }
    }

    .b-checkbox {
      pointer-events: none;
    }
  }

  &__controls {
    top            : -40px;
    margin         : 0 -40px;
    height         : 123px;
    z-index        : 2;
    padding        : 40px 40px 0 40px;
    display        : flex;
    position       : sticky;
    box-sizing     : border-box;
    background     : rgba(255, 255, 255, 0.9);
    margin-bottom  : 40px;
    justify-content: space-between;
  }

  &__limit {
    right          : 32px;
    bottom         : 0;
    padding        : 8px;
    display        : flex;
    position       : fixed;
    background     : white;
    border-radius  : 10px;
    margin-bottom  : 16px;
    justify-content: flex-end;
  }

  &__search {
    display: flex;

    .b-button {
      width   : 64px;
      position: absolute;
    }

    .b-documents__search-input {

      .b-textfield {
        &__prefix img {
          filter: brightness(0) saturate(100%) invert(15%) sepia(8%) saturate(710%) hue-rotate(173deg) brightness(99%) contrast(94%);
        }

        &__postfix {

          img {
            width : 24px;
            height: 24px;
          }

          button {
            transition: 0.2s;

            &:hover {
              cursor : pointer;
              opacity: 0.7;
            }
          }
        }
      }
    }

    &--full {
      .b-button {
        opacity: 0;
      }

      .b-documents__search-input {
        width  : 400px;
        opacity: 1;
      }
    }

    &--collapsed {
      .b-button {
        opacity: 1;
      }

      .b-documents__search-input {
        width  : 64px;
        opacity: 0;
      }

    }

    .b-select {
      margin-left: 40px;
    }
  }

  &__grid {
    display              : grid;
    grid-gap             : 45px;
    list-style           : none;
    justify-content      : space-between;
    grid-template-columns: repeat(auto-fill, 120px);
  }

  &__filters {
    display: none;
  }

  @include media('<tablet') {
    margin-top: -40px;

    &__select {
      display: none;
    }

    &__controls {
      height: 100px;
    }

    &__limit {
      right: 16px;
    }

    &__search {
      display: none;
    }

    &__grid {
      grid-gap: 24px;
    }

    &__filters {
      width  : 50px;
      padding: 0;
      display: block;

      img {
        width : 20px;
        height: 20px;
      }
    }
  }
}

.b-document {
  width   : 120px;
  height  : 170px;
  position: relative;

  &:hover {
    cursor: pointer;

    .b-document__type {
      opacity: 0;
    }

    .b-document__title,
    .b-document__delete {
      opacity: 1;
    }

    .b-document__backdrop {
      opacity: 0.5;
    }

    .b-document__preview {
      width : 140px;
      height: 170px;
      margin: 0 -10px;

      p {
        -webkit-line-clamp: 10;
      }

      img {
        &.b-document__preview--model {
          margin: 45px 30px;
        }
      }
    }
  }

  &__type {
    top             : -6px;
    right           : -6px;
    width           : 40px;
    height          : 40px;
    padding         : 8px;
    position        : absolute;
    box-sizing      : border-box;
    transition      : 0.4s;
    border-radius   : 13px;
    background-color: $blue-dark-bg;
  }

  &__preview {
    width           : 100%;
    height          : 120px;
    position        : absolute;
    overflow        : hidden;
    transition      : 0.4s;
    border-radius   : 30px;
    background-color: $grey;

    p {
      margin    : 16px;
      max-height: inherit;
      @include font(12px, 400, 14px);

      display           : -webkit-box;
      overflow          : hidden;
      max-width         : 100%;
      word-wrap         : break-word;
      transition        : 0.4s;
      white-space       : pre;
      text-overflow     : ellipsis;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
    }

    img {
      width     : inherit;
      height    : inherit;
      object-fit: cover;

      &.b-document__preview--model {
        width     : 80px;
        height    : 80px;
        margin    : 20px;
        transition: 0.4s;
      }
    }
  }

  &__text {
    height     : 40px;
    display    : -webkit-box;
    max-width  : 100%;
    overflow-y : hidden;
    text-align : center;
    margin-top : 128px;
    font-weight: 500;
  }

  &__title {
    color        : white;
    width        : 140px;
    bottom       : 0;
    z-index      : 1;
    opacity      : 0;
    padding      : 8px 16px;
    position     : absolute;
    max-height   : 73px;
    text-align   : center;
    box-sizing   : border-box;
    transition   : 0.2s;
    border-radius: 0 0 30px 30px;

    &:after {
      left                 : 0;
      right                : 0;
      height               : 100%;
      bottom               : 0;
      z-index              : -1;
      content              : '';
      opacity              : .5;
      position             : absolute;
      transform            : rotate(180deg);
      background           : linear-gradient(180deg, rgba(45, 45, 45, 0.8), rgba(148, 148, 148, 0) 88.92%, hsla(0, 0%, 84.7%, .00008) 0);
      background-blend-mode: multiply;
    }
  }

  &__text,
  &__title {
    word-wrap         : break-word;
    text-overflow     : ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__delete {
    top             : -18px;
    left            : -18px;
    width           : 40px;
    height          : 40px;
    cursor          : pointer;
    z-index         : 1;
    padding         : 8px;
    opacity         : 0;
    position        : absolute;
    box-sizing      : border-box;
    transition      : 0.4s;
    border-radius   : 13px;
    background-color: $red;
  }

  &__backdrop {
    top                  : 30%;
    left                 : 0;
    right                : 0;
    bottom               : 0;
    margin               : 0 -10px;
    z-index              : 1;
    opacity              : 0;
    position             : absolute;
    transform            : rotate(180deg);
    transition           : opacity 0.2s;
    background           : linear-gradient(180deg, rgba(94, 104, 107, 0.8), hsla(0, 0%, 84.7%, .00008) 88.92%, hsla(0, 0%, 84.7%, .00008) 0);
    border-radius        : 30px 30px 0 0;
    transition-delay     : 0.2s;
    background-blend-mode: multiply;
  }

  @include media('<tablet') {
    &__delete {
      display: none;
    }

    &:hover {
      cursor: pointer;

      .b-document__type {
        opacity: 1;
      }

      .b-document__title,
      .b-document__delete {
        opacity: 0;
      }

      .b-document__backdrop {
        opacity: 0;
      }

      .b-document__preview {
        width : 120px;
        height: 120px;
        margin: 0;

        p {
          -webkit-line-clamp: initial;
        }

        img {
          &.b-document__preview--model {
            margin: 20px;
          }
        }
      }
    }
  }
}

.b-uploader {
  width    : 100%;
  max-width: 1040px;

  .b-modal {
    &__content {
      padding: 40px;
    }
  }

  &__sm {
    display: none;
  }

  &__title {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
  }

  &__types {
    margin : 32px 0;
    display: flex;

    .b-file-type {
      margin-right: 46px;

      &:last-child {
        margin: 0;
      }
    }
  }

  &__input {
    display      : flex;
    margin-bottom: 32px;

    img {
      filter: brightness(0) saturate(100%) invert(62%) sepia(5%) saturate(518%) hue-rotate(164deg) brightness(93%) contrast(88%);
    }

    .b-textfield {
      flex        : 1;
      margin-right: 24px;
    }
  }

  &__files {
    display      : flex;
    flex-wrap    : wrap;
    max-width    : 100%;
    margin-right : -30px;
    margin-bottom: 20px;

    .b-file {
      margin-top   : 10px;
      margin-right : 30px;
      margin-bottom: 20px;
    }
  }

  &__footer {
    display        : flex;
    margin-top     : 40px;
    justify-content: center;

    .b-button {
      padding: 0 99px;
    }
  }

  @include media('<tablet') {
    max-width: calc(100vw - 32px);

    .b-modal {
      &__content {
        margin : 0;
        padding: 16px;
      }
    }

    &__lg {
      display: none;
    }

    &__sm {
      display: block;
    }

    &__title {
      align-items   : flex-start;
      flex-direction: column;
    }

    &__types {
      margin        : 0;
      overflow      : auto;
      padding-bottom: 16px;

      .b-file-type {
        margin-right: 24px;
      }
    }

    &__types-wrapper {
      margin  : 24px 0;
      height  : 35px;
      overflow: hidden;
    }

    &__input {
      margin-bottom : 24px;
      flex-direction: column;

      .b-textfield {
        flex         : unset;
        margin-right : 0;
        margin-bottom: 16px;
      }
    }

    &__files {
      margin-right: -24px;

      .b-file {
        margin-right: 24px;
      }
    }

    &__footer {
      margin-top: 24px;

      .b-button {
        width  : 100%;
        padding: initial;
      }
    }
  }
}

.b-file-type {
  display: flex;

  &__icon {
    width        : 40px;
    height       : 40px;
    padding      : 8px;
    box-sizing   : border-box;
    background   : #F8F8F8;
    margin-right : 16px;
    border-radius: 13px;
  }

  &__inner {
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
  }

  @include media('<tablet') {
    &__icon {
      width        : 35px;
      height       : 35px;
      margin-right : 8px;
      border-radius: 10px;

      img {
        width : 19px;
        height: 19px;
      }
    }

    .b-text {
      white-space: nowrap;
    }
  }
}

.b-dropzone {
  width          : 100%;
  height         : 220px;
  cursor         : pointer;
  display        : flex;
  line-height    : 1.5;
  background     : rgba(54, 160, 242, 0.03);
  align-items    : center;
  border-radius  : 10px;
  flex-direction : column;
  justify-content: center;

  img {
    width : 85px;
    height: 85px;
  }

  &--disabled {
    opacity: 0.5;
  }

  @include media('<tablet') {
    height     : 100px;
    line-height: 1;

    img {
      width : 60px;
      height: 60px;
    }
  }
}

.b-file {
  width        : 80px;
  height       : 80px;
  position     : relative;
  border-radius: 20px;

  p {
    margin    : 8px 12px;
    max-height: inherit;
    @include font(12px, 400, 14px);

    display           : -webkit-box;
    overflow          : hidden;
    max-width         : 100%;
    word-wrap         : break-word;
    transition        : 0.4s;
    text-overflow     : ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  &__icon {
    width : 60px;
    height: 60px;
    margin: 10px;

  }

  &__delete {
    top     : -16px;
    right   : -16px;
    cursor  : pointer;
    position: absolute;

    &:hover {
      opacity: 0.8;
    }
  }

  &__status {
    top     : 20px;
    left    : 20px;
    width   : 40px;
    height  : 40px;
    position: absolute;
  }

  &__overlay {
    top          : 0;
    left         : 0;
    right        : 0;
    bottom       : 0;
    position     : absolute;
    transition   : 0.4s;
    border-radius: 20px;

    &--done {
      background: rgba(0, 0, 0, 0.5);
    }

    &--failed {
      background: rgba(235, 87, 87, 0.5);
    }

    &--loading {
      background: rgba(54, 160, 242, 0.5);
    }
  }

  &__preview {
    width        : 100%;
    height       : 100%;
    object-fit   : cover;
    border-radius: 20px;
  }
}

.b-document-modal {
  width        : 100%;
  height       : 100%;
  margin       : 0 20px;
  max-width    : 920px;
  max-height   : 642px;
  border-radius: 30px;

  .b-modal__close {
    display: none;
  }

  .b-modal__content {
    width        : 100%;
    height       : 100%;
    padding      : 0;
    border-radius: 30px;
  }

  &__header {
    width          : 100%;
    display        : flex;
    max-width      : 920px;
    align-items    : center;
    margin-bottom  : 14px;
    justify-content: space-between;
  }

  &__control {
    margin-left: 16px;

    &--next {
      transform: scale(-1, 1);
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__content {
    height         : 100%;
    display        : flex;
    overflow       : hidden;
    align-items    : center;
    border-radius  : 30px;
    justify-content: center;
  }

  &__image {
    width     : 100%;
    height    : 100%;
    object-fit: cover;
  }

  &__iframe {
    width : 100%;
    height: 100%;
    border: none;
  }

  &__text {
    width      : 100%;
    margin     : 40px 0;
    padding    : 0 40px;
    max-height : 70%;
    align-self : flex-start;
    text-align : left;
    overflow-y : auto;
    white-space: pre-line;
  }

  &__menu {
    height       : 40px;
    background   : $black;
    border-radius: 10px;

    button {
      padding   : 8px;
      cursor    : pointer;
      transition: 0.2s;

      img {
        filter: brightness(0) saturate(100%) invert(46%) sepia(19%) saturate(1787%) hue-rotate(312deg) brightness(99%) contrast(98%);
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  model-viewer {
    width               : 80%;
    height              : 80%;
    align-self          : flex-start;
    --progress-bar-color: #36A0F2;
  }

  &__overlay {
    left         : 0;
    right        : 0;
    color        : white;
    height       : 130px;
    bottom       : 0;
    padding      : 38px 32px 38px;
    position     : absolute;
    box-sizing   : border-box;
    background   : linear-gradient(141.47deg, rgba(0, 0, 0, 0) -6.03%, rgba(0, 0, 0, 0.6) 99.32%);
    border-radius: 0px 0px 30px 30px;
    @include font(24px, 500);
  }

  @include media('<tablet') {
    max-width : calc(100vw - 32px);
    max-height: calc(100vh - 150px);

    &__header {
      margin   : -32px 0 0 0;
      padding  : 16px;
      max-width: calc(100vw - 32px);
    }

    &__control {
      margin-left: 8px;
    }

    &__text {
      margin    : 24px 0;
      padding   : 0 24px;
      max-height: 70%;
    }

    &__overlay {
      height : 80px;
      padding: 24px;
      @include font(20px, 500);
    }
  }
}

.b-limit-modal {
  .b-modal__content {
    max-width: 500px;
  }

  .b-button {
    margin-top: 24px;
  }
}

.b-document-filters {
  display: none;

  &__inner {
    padding: 16px 24px 24px 16px;
  }

  &__option {
    display        : flex;
    padding        : 8px 0;
    align-items    : center;
    justify-content: space-between;
  }

  .b-checkbox {
    pointer-events: none;
  }

  .b-textfield {
    &__prefix img {
      filter: brightness(0) saturate(100%) invert(15%) sepia(8%) saturate(710%) hue-rotate(173deg) brightness(99%) contrast(94%);
    }
  }

  .b-button {
    width: 100%;
  }

  @include media('<tablet') {
    display: unset;
  }
}